import * as React from "react";
import { Page } from "../components/Page";
import { Header } from "../components/Header";
import { Container } from "../components/Container";
import { graphql, PageProps } from "gatsby";
import { BlogEntry } from "../components/BlogEntry";
import SEO from "../components/Seo";

// markup
const BlogPage = ({ data }: PageProps<Queries.BlogPageBlogListQuery>) => {
  return (
    <Page>
      <Header />
      <SEO title={"Blog"} />
      <Container>
        <h1>Blog</h1>
        <div style={{ alignSelf: "flex-start" }}>
          {data.allMdx.edges.map((edge) => (
            <BlogEntry key={edge.node.id} edge={edge} />
          ))}
        </div>
      </Container>
    </Page>
  );
};

export const pageQuery = graphql`
  query BlogPageBlogList {
    allMdx(filter: { fileAbsolutePath: { regex: "content/blog/i" } }) {
      edges {
        node {
          id
          slug
          frontmatter {
            title
            date
            path
            tags
          }

          timeToRead
          excerpt
        }
      }
    }
  }
`;

export default BlogPage;
